<template>
  <v-dialog v-model="showModal" scrollable max-width="600px" @click:outside="handleClose">
    <!-- @keydown="handleClose" -->
    <v-card color="white">
      <v-card-title class="text-subtitile-2 white--text py-1" :class="titleClass">
        <v-icon v-if="error" color="white" large>mdi-close-octagon</v-icon>
        <v-icon v-if="success" color="white" large>mdi-checkbox-marked-circle-outline</v-icon>
        <span v-if="upsPaperlessCreateErrorFlag && isReCall()">{{ messages.UPS_THROUGH_PAPERLESS_CREATE }}</span>
        <span v-if="upsPaperlessDeleteErrorFlag && isReCall()">{{ messages.UPS_THROUGH_PAPERLESS_DELETE }}</span>
        <span v-if="success">{{ label.SUCCSESS }}</span>
      </v-card-title>

      <v-card-text class="py-3 grey--text text--darken-1" v-html="modalMessage"> </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="!success && isReCall()"
          color="primary"
          :loading="uploadLoading"
          :disabled="uploadLoading"
          @click="reCallUpsShipmentApi"
        >
          {{ label.YES }}
        </v-btn>
        <v-btn
          v-if="!success && isReCall()"
          color="primary"
          :loading="uploadLoading"
          :disabled="uploadLoading"
          @click="handleConfirm"
        >
          {{ label.NO }}
        </v-btn>

        <v-btn v-if="success || !isReCall()" class="white--text" color="green darken-1" @click="handleClose">
          {{ label.CLOSE }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { label } from '@/consts.js';
import { mapActions, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      error: 'upsShipment/error',
      success: 'upsShipment/success',
      uploadLoading: 'upsShipment/uploadLoading',
      showModal: 'upsShipment/showModal',
      modalMessage: 'upsShipment/modalMessage',
      messages: 'defined/messages',
      upsPaperlessCreateErrorFlag: 'upsShipment/upsPaperlessCreateErrorFlag',
      upsPaperlessDeleteErrorFlag: 'upsShipment/upsPaperlessDeleteErrorFlag',
    }),
    label() {
      return label;
    },
    titleClass() {
      return {
        'red accent-2': this.error,
        'light-blue lighten-1': !this.error && !this.success,
        success: this.success,
      };
    },
  },
  methods: {
    ...mapActions({
      toggleShowModal: 'upsShipment/toggleShowModal',
      setSuccess: 'upsShipment/setSuccess',
      resetModalContents: 'upsShipment/resetModalContents',
      apiCreateShipmentUPS: 'api/createShipmentUPS',
      apiCancelShipmentUPS: 'api/cancelShipmentUPS',
    }),
    handleClose() {
      this.resetModalContents();
      this.setSuccess(false);
      this.toggleShowModal();
      this.$emit('click:close');
    },
    handleConfirm() {
      if (!this.error) {
        this.setClickedConfirmFlag(true);
        this.$store.commit('upsShipment/setUpsPreRequestBody', {});
      } else {
        this.handleClose();
      }
    },
    reCallUpsShipmentApi() {
      if (this.upsPaperlessCreateErrorFlag) {
        this.apiCreateShipmentUPS({ reCallFlag: true });
      } else if (this.upsPaperlessDeleteErrorFlag) {
        this.apiCancelShipmentUPS({ reCallFlag: true });
      }
    },
    isReCall() {
      return this.modalMessage?.indexOf('128238') !== -1 || this.modalMessage?.substr(11, 3) === '959';
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
</style>
